.layersWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.GameWrapper {
  gap: 0;
}

.otherWrapper {
  position: absolute;
  bottom: 0;
  width: 100vw;
  height: 100svh;
  overflow: hidden;
  pointer-events: none;
}

.backgroundGradient {
  position: absolute;
  z-index: -3;
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 33.333333%,
    rgba(0, 0, 0, 1) 66.6666666666666%,
    rgba(0, 0, 0, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 33.333333%,
    rgba(0, 0, 0, 1) 66.6666666666666%,
    rgba(0, 0, 0, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 33.333333%,
    rgba(0, 0, 0, 1) 66.6666666666666%,
    rgba(0, 0, 0, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#000000",GradientType=1);
}

.brick {
  transform: rotate(0deg);  /*makes this stupid shit behave*/
  opacity: 0;
  transition: 0.1s;
  pointer-events: none;
  width: 150%;
  height: 150%;
  display:flex;
  justify-content: center;
  position: absolute;
  align-items: center;
  clip-path: polygon(
    50% 0%,
    50% 0%,
    50% 0%,
    50% 0%,
    100% 50%,
    100% 50%,
    100% 50%,
    100% 50%,
    50% 100%,
    50% 100%,
    50% 100%,
    50% 100%,
    0% 50%,
    0% 50%,
    0% 50%,
    0% 50%
  );
}

.innerBrick {
  pointer-events: none;
  transition: 0.5s;
  width: 80%;
  height: 80%;
  clip-path: polygon(
    50% 0%,
    50% 0%,
    50% 0%,
    50% 0%,
    100% 50%,
    100% 50%,
    100% 50%,
    100% 50%,
    50% 100%,
    50% 100%,
    50% 100%,
    50% 100%,
    0% 50%,
    0% 50%,
    0% 50%,
    0% 50%
  );
}

.walled {
  transform: rotate(22.5deg);
  pointer-events: none;
  transition: 0.5s;
  width: 196%;
  height: 196%;
  position: absolute;
  display:flex;
  justify-content: center;
  align-items: center;
  clip-path: polygon(
    50% 1%,
    63% 20%,
    86% 15%,
    81% 38%,
    100% 50%,
    81% 63%,
    86% 86%,
    63% 81%,
    50% 100%,
    37% 81%,
    14% 86%,
    19% 63%,
    0% 50%,
    19% 38%,
    14% 15%,
    37% 20%
  );
}

.walled div {
  width: 80%;
  height: 80%;
  left: 10%;
  top: 10%;
  clip-path: polygon(
    50% 1%,
    63% 20%,
    86% 15%,
    81% 38%,
    100% 50%,
    81% 63%,
    86% 86%,
    63% 81%,
    50% 100%,
    37% 81%,
    14% 86%,
    19% 63%,
    0% 50%,
    19% 38%,
    14% 15%,
    37% 20%
  );
}

.layer {
  position: absolute;
}

.spot {
  position: absolute;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20%;
}

.line1 {
  z-index: -2;
  pointer-events: none;
  position: absolute;
  width: 33.33333333%;
  left: 5%;
  top: calc(50% - 3px);
  height: 6px;
}
.line2 {
  z-index: -2;
  pointer-events: none;
  position: absolute;
  top: 5%;
  left: calc(50% - 3px);
  height: 33.333333333%;
  width: 6px;
}

.line3 {
  z-index: -2;
  pointer-events: none;
  position: absolute;
  width: 33.33333333%;
  right: 5%;
  top: calc(50% - 3px);
  height: 6px;
}
.line4 {
  z-index: -2;
  pointer-events: none;
  position: absolute;
  bottom: 5%;
  left: calc(50% - 3px);
  height: 33.333333333%;
  width: 6px;
}

.teamInfo {
  display: flex;
  position: absolute;
  justify-content: space-between;
  width: 49%;
  align-items: center;
  transition: 0.3s;
  bottom: 0;
}

.TeamText {
  user-select: none;
  margin: 0;
  font-size: 2vh;
  color: #000;
  line-height: 4vh;
  width: 33%;
}

.TeamState {
  user-select: none;
  font-size: 4vh;
  margin: 0;
  width: 66%;
  height: 100%;
}

.white {
  left: 1%;
}

.black {
  right: 1%;
  color: #fff;
}

.whi {
  text-align: start;
}

.bla {
  text-align: end;
}

.white {
  text-align: end;
}

.TeamText span {
  font-size: 6vh;
}

@media screen and (max-width: 900px) {
  .gameInfo {
    flex-direction: column;
  }
  .teamInfo {
    position: absolute;
    width: 80%;
    margin: 0 10%;
    justify-content: space-evenly;
  }

  .black {
    right: unset;
  }

  .white {
    left:unset
  }

  .TeamText {
    font-size: 2vh;
    line-height: 4vh;
  }

  .TeamText span {
    font-size: 6vh;
  }

  .TeamState {
    font-size: 4vh;
  }
}

@media screen and (max-height: 450px) {
  .layer {
    border-width: 2px !important;
  }

  .line1 {
    top: calc(50% - 2px);
    height: 2px;
  }
  .line2 {
    left: calc(50% - 2px);
    width: 2px;
  }
  .line3 {
    top: calc(50% - 2px);
    height: 2px;
  }
  .line4 {
    left: calc(50% - 2px);
    width: 2px;
  }

  .TeamText {
    font-size: 1.2vw;
    line-height: 1.8vw;
  }

  .TeamText span {
    font-size: 3vw;
  }

  .TeamState {
    font-size: 2vw;
  }
}

@media screen and (max-width: 450px) {
  .layer {
    border-width: 2px !important;
  }

  .line1 {
    top: calc(50% - 2px);
    height: 2px;
  }
  .line2 {
    left: calc(50% - 2px);
    width: 2px;
  }
  .line3 {
    top: calc(50% - 2px);
    height: 2px;
  }
  .line4 {
    left: calc(50% - 2px);
    width: 2px;
  }

  .TeamText {
    font-size: 1.4vh;
    line-height: 2.6vh;
  }

  .TeamText span {
    font-size: 5vh;
  }

  .TeamState {
    font-size: 2.5vh;
  }

  .black {
    flex-direction: row-reverse;
    top: 2.5%;
    bottom: unset;
    transform: rotate(180deg);
    text-align: end;
  }

  .white {
    bottom:2.5%;
  }

  .bla {
    text-align: start;
  }

  .backgroundGradient {
    background: rgb(255,255,255);
    background: -moz-linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 33.333333%, rgba(0,0,0,1) 66.6666666666666%, rgba(0,0,0,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 33.333333%, rgba(0,0,0,1) 66.6666666666666%, rgba(0,0,0,1) 100%);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 33.333333%, rgba(0,0,0,1) 66.6666666666666%, rgba(0,0,0,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#000000",GradientType=1); 
    }
}

@keyframes line-border-in {
  0% {
    background-color: #fff;
  }
  100% {
    background-color: #000;
  }
}

@keyframes line-border-out {
  0% {
    background-color: #000;
  }
  100% {
    background-color: #fff;
  }
}

@keyframes gradient-in {
  0% {
    left: -200vw;
  }
  100% {
    left: 0;
  }
}

@keyframes gradient-out {
  0% {
    left: 0;
  }
  100% {
    left: -200vw;
  }
}

@keyframes gradient-opposite-in {
  0% {
    top: -200svh;
  }
  100% {
    top: 0;
  }
}

@keyframes gradient-opposite-out {
  0% {
    top: 0;
  }
  100% {
    top: -200svh;
  }
}

@keyframes layer-border-in {
  0% {
    border-color: #fff;
  }
  100% {
    border-color: #000;
  }
}

@keyframes layer-border-out {
  0% {
    border-color: #000;
  }
  100% {
    border-color: #fff;
  }
}

@keyframes spot-color-in {
  0% {
    background-color: #404040;
  }
  100% {
    background-color: #bfbfbf;
  }
}

@keyframes spot-color-out {
  0% {
    background-color: #bfbfbf;
  }
  100% {
    background-color: #404040;
  }
}
